<template>
  <header id="header" class="wpo-site-header wpo-header-style-2">
    <div class="topbar" v-if="contact.status === 'published'">
      <div class="container">
        <div class="row">
          <div class="col col-md-6 col-sm-7 col-12">
            <div class="contact-intro">
              <ul>
                <li><i class="fi flaticon-call"></i>{{contact.phone}}</li>
                <li><i class="fi flaticon-envelope"></i> {{contact.email}}</li>
              </ul>
            </div>
          </div>
          <div class="col col-md-6 col-sm-5 col-12">
            <div class="contact-info donate-now-wrapper">
              <ul>
                <li>
                  <a href="https://www.paypal.com/donate/?hosted_button_id=J2JXZJ8WMLJJG" class="theme-btn donate-now">
                    {{ contact.button_text }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- end topbar -->
    <nav class="navigation navbar navbar-default nav-bar-background" :class="{'sticky-on': navBar}">
      <div class="container">
        <div class="navbar-header">
          <button type="button" class="open-btn" @click="openMenu = true">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <router-link to="/" class="navbar-brand">
            <div class="logo-wrapper">
              <img src="../assets/images/logo-1.png" height="70px" alt="logo">
              <div class="logo-text">
                <span>asia adams</span>
                <span>Save OUR Children Foundation</span>
              </div>
            </div>
          </router-link>
        </div>
        <div id="navbar" class="navbar-collapse collapse navbar-right navigation-holder" :class="{'slideInn': openMenu}">
          <button class="close-navbar" @click="openMenu = false"><i class="ti-close"></i></button>
          <ul class="nav navbar-nav">
            <li class="menu-item-has-children">
              <router-link to="/">Home</router-link>
            </li>
            <li>
              <router-link to="/about">About</router-link>
            </li>
            <li><router-link to="/events">Event</router-link></li>
            <li>
              <router-link to="/gallery">Gallery</router-link>
            </li>
            <li class="menu-item-has-children">
              <router-link :to="{ path: '/articles', query: query({offset: 0})}"><a>Blog</a></router-link>
            </li>
            <li><router-link to="/contact">Resources</router-link></li>
          </ul>
        </div><!-- end of nav-collapse -->
      </div><!-- end of container -->
    </nav>
  </header>
</template>

<script>
    // import $ from 'jquery'

    export default {
        name: "nav-bar",
        data() {
            return {
                openMenu: false,
                scroll: true,
                navBar: false
            }
        },
        props: ['contact'],
        mounted() {
            window.addEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll: async function  () {
                let scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
                scrollPosition = scrollPosition + window.innerHeight;
                if(scrollPosition >= 2000 && this.scroll) {
                    this.navBar = true;
                    this.scroll = false;
                } else if (scrollPosition <= 2000 && !this.scroll) {
                    this.navBar = false;
                    this.scroll = true;
                }
            },
            query(newQuery) {
                return {
                    ...this.$route.query,
                    ...newQuery
                }
            },
        }
    }
</script>

<style scoped>
  .theme-btn.donate-now:hover {
    color: #fff !important;
  }

  .donate-now-wrapper ul li {
    padding: 0 !important;
  }

  .wpo-header-style-2 .contact-intro ul li:first-child {
    border-right: 1px solid rgba(161, 78, 198, 0.22);
  }
  .wpo-header-style-3 .contact-intro ul li:first-child {
    border-right: 1px solid #dfe2e6;
  }
  .navbar-default {
    background-color: transparent;
  }
  .nav-bar-background {
    transition: ease-in-out 0.3s;
  }
  .sticky-on {
    position: fixed;
    top: 0;
    z-index: 111;
    width: 100%;
    background: #f7e6ff;
    transform: translateY(0);
    -webkit-animation: nav-bar 0.4s ease-in-out; /* Safari 4+ */
    -moz-animation:    nav-bar 0.4s ease-in-out; /* Fx 5+ */
    -o-animation:      nav-bar 0.4s ease-in-out; /* Opera 12+ */
    animation:         nav-bar 0.4s ease-in-out; /* IE 10+, Fx 29+ */
  }
  @-webkit-keyframes nav-bar {
    0%   { transform: translateY(-100%); opacity: 0 }
    100% { transform: translateY(0); opacity: 1 }
  }
  @-moz-keyframes nav-bar {
    0%   { transform: translateY(-100%); opacity: 0 }
    100% { transform: translateY(0); opacity: 1 }
  }
  @-o-keyframes nav-bar {
    0%   { transform: translateY(-100%); opacity: 0 }
    100% { transform: translateY(0); opacity: 1 }
  }
  @keyframes nav-bar {
    0%   { transform: translateY(-100%); opacity: 0 }
    100% { transform: translateY(0); opacity: 1 }
  }
</style>
