import Vue from 'vue'
import App from './App.vue'
import router from './router';
import axios from "axios";
import VueAxios from "vue-axios";
import VueMoment from 'vue-moment';
import AOS from 'aos';
import VModal from 'vue-js-modal';
import 'aos/dist/aos.css';

import VueNumber from 'vue-number-animation'

Vue.use(VueNumber)

const http = axios.create({
    baseURL: 'https://flexiblesites.net/asiaadams/'
});

Vue.config.productionTip = false;

Vue.config.productionTip = false;
window.axios = require('axios');
Vue.use(VueAxios, http, axios);
Vue.use(VueMoment);
Vue.use(VModal);

new Vue({
    created () {
        AOS.init()
    },
    router,
    render: h => h(App),
}).$mount('#app')
