import Vue from "vue";
let token = "LYo2NrDYDfj2Px-eGXAEVLCleAjXTKUXclOBiePm2A4";

export default {
    contact: (filter = '') => {
        return Vue.axios
            .get(`items/contact?status=published&fields=${filter}`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    headerCarousel: () => {
        return Vue.axios
            .get(`items/header_carousel?status=published`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    getFiles: (id) => {
        return Vue.axios
            .get(`/files/${id}?status=published`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    introduction: () => {
        return Vue.axios
            .get(`items/introduction?status=published`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    team: () => {
        return Vue.axios
            .get(`items/team?status=published`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    events: () => {
        return Vue.axios
            .get(`items/events?status=published`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    footer: () => {
        return Vue.axios
            .get(`items/footer?status=published`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    causes: (fields = '') => {
        return Vue.axios
            .get(`items/causes?status=published&fields=${fields}`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    moreEvents: (offset = 0, limit = 6) => {
        return Vue.axios
            .get(`items/events?status=published&offset=${offset}&limit=${limit}`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    mission: (fields = '') => {
        return Vue.axios
            .get(`items/about?fields=${fields}&status=published`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    gallery: (offset = 0, limit = 6) => {
        return Vue.axios
            .get(`items/gallery?status=published&sort=sort&offset=${offset}&limit=${limit}`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    blog: (offset = 0, limit = 3) => {
        return Vue.axios
            .get(`items/articles?status=published&sort=-date&offset=${offset}&limit=${limit}&fields=id,status,title,introduction,date,author,image,video_url,category,slug`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    recentBlog: (limit = 3, fields) => {
        return Vue.axios
            .get(`items/articles?status=published&sort=-date&limit=${limit}&fields=${fields}`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    recentEvents: (limit = 3) => {
        return Vue.axios
            .get(`items/events?status=published&sort=sort&limit=${limit}&fields=id,status,title,image,modified_on,link_url`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    blogDetail: (url) => {
        return Vue.axios
            .get(`items/articles?filter[slug]=${url}`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    resources: () => {
        return Vue.axios
            .get(`items/resources?status=published&sort=category,sort`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    },
    getCategoryName: (id) => {
        return Vue.axios
            .get(`items/resource_categories/${id}`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(() => null);
    }
}
