import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {
                layout: 'main',
                auth: true,
                title: 'Asia Adams Save OUR Children'
            },
            component: () => import('./pages/home.vue'),
        },
        {
            path: '/donate',
            name: 'donate',
            meta: {
                layout: 'main',
                auth: true,
                title: 'Donate to Us'
            },
            component: () => import('./pages/donate.vue'),
            beforeEnter(to, from, next) {
                // Redirect to PayPal when accessing the /donate route
                window.location.href = 'https://www.paypal.com/donate/?hosted_button_id=J2JXZJ8WMLJJG';
                // Prevent further navigation
                next(false);
            },
        },
        {
            path: '/events',
            name: 'events',
            meta: {
                auth: true,
                title: 'Asia Adams Save OUR Children'
            },
            component: () => import('./pages/events.vue'),
        },
        {
            path: '/about',
            name: 'about',
                        meta: {
                auth: true,
                title: 'Asia Adams Save OUR Children'
            },
            component: () => import('./pages/about.vue')
        },
        {
            path: '/gallery',
            name: 'gallery',
                        meta: {
                auth: true,
                title: 'Asia Adams Save OUR Children'
            },
            component: () => import('./pages/gallery.vue')
        },
        {
            path: '/articles',
            name: 'articles',
                        meta: {
                auth: true,
                title: 'Asia Adams Save OUR Children'
            },
            component: () => import('./pages/blog.vue')
        },
        {
            path: '/article/:url',
            name: 'article',
                        meta: {
                auth: true,
                title: 'Asia Adams Save OUR Children'
            },
            props: true,
            component: () => import('./pages/blog-details.vue')
        },
        {
            path: '/contact',
            name: 'contact',
                        meta: {
                auth: true,
                title: 'Asia Adams Save OUR Children'
            },
            component: () => import('./pages/contact.vue')
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => location.href = 'https://www.flexiblesites.net/admin/#/login?project=asiaadams'
        },
        {   path: '*',
            component: () => import('./pages/home.vue')
        }
    ]
})
