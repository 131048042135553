<template>
  <div id="app">
    <navBar :contact="contact_data" v-if="contact_data.length !== 0"/>
    <router-view />
     <neFooter :contact="contact_data" :footer="footer_data" />
    <div
      v-show="loading"
      style="
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      width: 100%;
      height: 100%;
    ">
      <div class="preloader">
        <div class="sk-folding-cube">
          <div class="sk-cube1 sk-cube"></div>
          <div class="sk-cube2 sk-cube"></div>
          <div class="sk-cube4 sk-cube"></div>
          <div class="sk-cube3 sk-cube"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import navBar from './components/nav-bar'
  import neFooter from './components/ne-footer'
  import api from "./services/api";

export default {
  name: 'App',
  data() {
    return {
      contact_data: [],
      footer_data: [],
      loading: true,
    }
  },
  components: {
      navBar,
      neFooter
  },
  async mounted () {
    setTimeout(() => {
        this.loading = false;
    }, 500);
    this.contact_data = await api.contact();
    this.contact_data = this.contact_data.data[0];

    this.footer_data = await api.footer();
    this.footer_data = this.footer_data.data[0];
  },
  watch: {
      '$route' (to) {
          document.title = to.meta.title || 'Asia Adams'
      }
  },
}
</script>



<style>
  @import '../src/assets/css/themify-icons.css';
  @import '../src/assets/css/font-awesome.min.css';
  @import '../src/assets/css/flaticon.css';
  @import '../src/assets/css/bootstrap.min.css';
  @import '../src/assets/css/slick.css';
  @import '../src/assets/css/slick-theme.css';
  @import '../src/assets/css/swiper.min.css';
  @import '../src/assets/css/jquery.fancybox.css';
  @import '../src/assets/css/odometer-theme-default.css';
  @import '../src/assets/css/nice-select.css';
  @import '../src/assets/css/style.css';
</style>
