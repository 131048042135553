<template>
  <div class="wpo-ne-footer">
    <section class="wpo-news-letter-section">
      <div class="container">
        <div class="row">
          <div class="col col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
            <div>
              <a href="https://www.paypal.com/donate/?hosted_button_id=J2JXZJ8WMLJJG" class="theme-btn donate-now">Support Us</a>
            </div>
            <!--
            <div class="wpo-newsletter">
              <h3>Follow Us</h3>
              <button type="submit">Subscribe</button>
              <div class="wpo-newsletter-form">
                <form>
                  <div>
                    <input type="text" placeholder="Enter Your Email" class="form-control">
                    <button type="submit">Subscribe</button>
                  </div>
                </form>
              </div>
            </div>
            -->
          </div>
        </div>
      </div>
    </section>
    <footer class="wpo-site-footer">
      <div class="wpo-upper-footer">
        <div class="container">
          <div class="row">
            <div class="col col-lg-3 col-md-3 col-sm-6" v-if="footer.status === 'published'">
              <div class="widget about-widget">
                <div class="logo widget-title">
                  <div class="logo-wrapper">
                    <img src="../assets/images/logo-1.png" height="50px" alt="logo">
                    <div class="logo-text">
                      <span>asia adams</span>
                      <span>Save OUR Children Foundation</span>
                    </div>
                  </div>
                </div>
                <p>{{footer.main_text}}</p>
                <ul>
                  <li><a :href="footer.facebook_link" v-if="footer.facebook_link" target="_blank"><i class="ti-facebook"></i></a></li>
                  <li><a :href="footer.twitter_link" v-if="footer.twitter_link" target="_blank"><i class="ti-twitter-alt"></i></a></li>
                  <li><a :href="footer.instagram_link" v-if="footer.instagram_link" target="_blank"><i class="ti-instagram"></i></a></li>
                  <li><a :href="footer.linkedin_link" v-if="footer.linkedin_link" target="_blank"><i class="ti-linkedin"></i></a></li>
                </ul>
              </div>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-6">
              <div class="widget link-widget resource-widget" v-if="recentBlog_data.length !== 0">
                <div class="widget-title">
                  <h3>Recent Articles</h3>
                </div>
                <div class="news-wrap" v-for="recentBlog in recentBlog_data" :key="recentBlog.id">
                  <template v-if="recentBlog.status === 'published'">
                    <div class="news-img">
                      <img :src="recentBlog.image" @error="setAltImg" alt="">
                    </div>
                    <div class="news-text">
                      <h3><router-link :to="{ name: 'article', params: params({url: recentBlog.slug}) }">{{recentBlog.title}}</router-link></h3>
                      <span>{{recentBlog.date | moment("MMMM DD, YYYY")}}</span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="col col-lg-2 col-md-3 col-sm-6">
              <div class="widget link-widget">
                <div class="widget-title">
                  <h3>Useful Links</h3>
                </div>
                <ul>
                  <li><router-link to="/">Home</router-link></li>
                  <li><router-link to="/about">About</router-link></li>
                  <li><router-link to="/events">Event</router-link></li>
                  <li><router-link to="/gallery">Gallery</router-link></li>
                  <li><router-link :to="{ path: '/articles', query: query({offset: 0})}"><a>Blog</a></router-link></li>
                  <li><router-link to="/contact">Resources</router-link></li>
                </ul>
              </div>
            </div>
            <div class="col col-lg-3 col-lg-offset-1 col-md-3 col-sm-6" v-if="contact.status === 'published'">
              <div class="widget market-widget wpo-service-link-widget">
                <div class="widget-title">
                  <h3>Contact </h3>
                </div>
                <div class="contact-ft">
                  <ul>
                    <li><i class="fi flaticon-pin"></i>{{contact.location}}</li>
                    <li><i class="fi flaticon-call"></i>{{contact.phone}}</li>
                    <li><i class="fi flaticon-envelope"></i>{{contact.email}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wpo-lower-footer pt-20 pb-20">
        <div class="container">
          <div class="row mb-20">
            <div class="col-lg-12">
              <p class="copyright">
                The Asia Adams Save OUR Children Foundation is a 501(c)(3) charitable organization and contributions are tax-deductible for income, gift, and estate taxes. Our EIN is 86-1140496
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="copyright">
                &copy; Copyright {{ currentYear }} All Right Reserved, Powered by <a href="https://www.flexiblesites.com/" target="_blank">FlexibleSites</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
    import api from "../services/api";
    import moment from 'moment';

    export default {
        name: "ne-footer",
        props: ['contact', 'footer'],
        data() {
            return {
                recentBlog_data: [],
                currentYear: '2012'
            }
        },
        methods: {
            query(newQuery) {
                return {
                    ...this.$route.query,
                    ...newQuery
                }
            },
            params(newParams) {
                return {
                    ...this.$route.params,
                    ...newParams
                }
            },
            setAltImg(event) {
                event.target.src = require("@/assets/images/default-image.jpg")
            },
        },
        async mounted() {
            this.currentYear = moment().format('YYYY');
            this.recentBlog_data = await api.recentBlog(2, 'id,status,title,date,image,slug');
            this.recentBlog_data = this.recentBlog_data.data;

            for (const value of this.recentBlog_data) {
                if (value.image !== null && typeof value.image === "number") {
                    let img = await api.getFiles(value.image);
                    if (img !== null) {
                        value.image = img.data['data']['full_url'];
                    }
                }
            }
        }
    }
</script>

<style scoped>
  .news-img {
    width: 80px;
    height: 65px;
  }
  .news-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .news-text a {
    color: #fff;
  }
  .news-text a:hover {
    color: #a14ec6;
  }
  .logo-wrapper .logo-text {
    color: #fff;
  }
  .logo-wrapper .logo-text span:first-child {
    font-size: 22px;
  }
  .logo-wrapper .logo-text span:last-child {
    font-size: 9px;
    margin-top: -4px;
  }
  .donate-now {
    border-radius: 36px;
  }
  .donate-now:hover {
    background-color: #8c3baf;
  }
</style>
